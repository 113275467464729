import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { AboutHeader, BannerLeft, BannerButton } from "../utils"
// import ReservationForm from "../components/globals/ReservationForm"

import img from "../images/bcg/v-day-banner.jpg"

const ValentinesPage = () => (
  <Layout>
    <SEO title="Valentine's Day at Traditions" keywords={[`Valentine's Day in Clemson, Seneca, and greater Upstate SC`]} />
    <AboutHeader img={img}>
      <BannerLeft title="Valentine's Day Special" subtitle="Walk-ins Welcome">
        <hr />
        <p>
          For the spontaneous romantics out there, walk-ins are welcome at Traditions on the Lake.
        </p>
      </BannerLeft>
    </AboutHeader>

    <section id="tailgate">
      <div className="row px-0 py-5 justify-content-center">
        <div className="col-12 col-md-10 col-lg-9 px-0 py-4 mx-0">
          <h2>Valentine's Day Special</h2>
          <br />
          <br />
          <p>
          Bring your special someone to Traditions on the Lake for a romantic and delicious Valentine's Day meal. Our full menu and a few February 14-only specials will be available.
          </p>
          <br />
          <br />
          <p>
          Start your evening with Italian wedding soup, then choose from tender, oven-roasted pork belly or honey-glazed salmon, or share two 7-ounce filets: one "black and blue" and one Oscar-style. Cap off your night with a slice of decadent white raspberry cheesecake, or choose from our other desserts.
          </p>
          <br />
          <br />

        </div>
      </div>
      <h2>Walk-ins Welcome</h2>
      <div className="row px-0 justify-content-center catering-form" >
        <BannerButton>
          <a href="tel:864-722-2800" rel="noopener noreferrer">864-722-2800</a>
        </BannerButton>
      </div>
      <br />
      <br />
    </section>
  </Layout>
)

export default ValentinesPage
